import React, {useEffect, useState } from 'react';
import './App.css';
import { Link, Route, Routes } from 'react-router-dom';
import NavBar from './Components/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import Deals from './Components/Deals';
import Home from './Components/Home';
import TermsOfUse from './Components/TermsOfUse';
import AboutUs from './Components/AboutUs';
import { socket } from './socket';
import { Blog } from './Components/Blog';
import Tips from './Components/Tips';
import { sortedUniqBy } from 'lodash';
import BuyBox from './Components/Buybox';
import { BoostConversions } from './Components/BoostConversions';
import PrimeDay from './Components/PrimeDay';
import News from './Components/News';
import Prime from './Components/Prime';
import PrimeDayOct from './Components/PrimeDayOct';
import AmazonWareHouse from './Components/AmazonWarehouse';
import TradeIn from './Components/TradeIn';
import PrivacyPolicy from './Components/PrivacyPolicy';
import AffiliateDisclosure from './Components/AffiliateDisclosure';
import PrimeDayOver from './Components/PrimeDayOver';
import UsingAi from './Components/UsingAI';

function App() {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const loadData = (loadDataArray: Record<number, any>) => {
      const arrayData = sortedUniqBy(Object.values(loadDataArray), 'asin');
      return setData(arrayData.reverse());
    }
    socket.on('loadData', loadData);

    return () => {
      socket.off('loadData', loadData);
    }
  }, []);
  return (
    <div className="App">
      <NavBar />
      <div style={{ background: `url(${'https://www.toptal.com/designers/subtlepatterns/uploads/triangle-mosaic.png'})`, 
        backgroundSize: 'cover', height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
      }}> 
        <Routes>
          <Route path="/" element={ <Home /> }/>
          <Route path="/deals" element={ <Deals data={ data } /> } />
          <Route path="/aboutUs" element={ <AboutUs />} />
          <Route path="/terms" element={ <TermsOfUse />} />
          <Route path="/news" element={ <News /> } />
          <Route path="/blog" element={ <Blog />} />
          <Route path="/tips" element={ <Tips /> } />
          <Route path="/boostConversions" element={ <BoostConversions /> } />
          <Route path="/buyBox" element={ <BuyBox /> } />
          <Route path="/primeDealDays"  element={ <PrimeDay />} />
          <Route path="/primeBenefits" element={ < Prime /> } />
          <Route path="/primeStartsNow" element={ < PrimeDayOct /> } />
          <Route path="/amazonWarehouse" element={ <AmazonWareHouse /> } />
          <Route path="tradeIn" element={ <TradeIn />} />
          <Route path="/privacyPolicy" element={ <PrivacyPolicy />} />
          <Route path="/affiliateDisclosure" element={ <AffiliateDisclosure />} />
          <Route path="/primeDayOver" element={ <PrimeDayOver /> } />
          <Route path="/smartShoppingAi" element={ <UsingAi /> } />
        </Routes>

      </div>
      <div className='row mt-5' style={{ justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
        <ul style={{ listStyle: 'none', paddingLeft: 0, display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' ,textAlign: 'center', alignContent: 'space-between', gap: 30}}>
        <li className='footerLi'><Link className="footerLink" to="/affiliateDisclosure">Affiliate Disclosure</Link></li>
        <li className='footerLi'><Link className="footerLink" to="/privacyPolicy">Privacy Policy</Link></li>
        <li className='footerLi'><Link className="footerLink" to="/terms">Terms of Use</Link></li>
        </ul>
        </div>
      <footer className='text-muted text-center col-8' style={{ fontSize: '70%'}}>Amazon Affiliate Disclosure
DC Thrift Shop is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees. <br></br>
<b>Important Disclosure: </b>
We may earn commissions from purchases made through our affiliate links.
All products listed on our website are Amazon affiliate links.
Prices and availability may vary based on your location and Amazon's pricing policies.
© Copyright 2024 DCThriftShop USA All Rights Reserved. Other products or company names mentioned herein may be trademarks of their respective owners. By using this site or any related content you agree that you have read and understood our <Link style={{ textDecoration: 'none', color: 'black' }} to="/terms">Terms of Use</Link></footer>
      </div>
    </div>
  );
}

export default App;
