import { Link } from 'react-router-dom';

export default function PrimeDayOct() {
    return (
        <div className="postContainer">
        <div className="post">
            <h1>BREAKING: Amazon Prime Deals Day is Here!</h1>
            <span className="postPublish">Published on October 8th, 2024</span>

            <img className="postImg" src='https://www.tennessean.com/gcdn/authoring/authoring-images/2024/06/24/USAT/74193726007-prime-day-faq-62524.png?crop=2997,1685,x0,y0&width=2997&height=1685&format=pjpg&auto=webp' alt="Prime Day Deals with pink box"/>

            <p>48-Hour Shopping Extravaganza Begins Now</p>

        <p>Get ready to save big! Amazon Prime Deals Day, the highly anticipated shopping event, has officially kicked off. For 48 hours, Prime members can snag incredible deals on millions of products.</p>

        <h3>Exclusive Deals for Prime Members</h3>
        <ul>
            <li>Deep discounts on top brands and products</li>
            <li>Limited-time Lightning Deals</li>
            <li>Exclusive access to new releases and bestsellers</li>
        </ul>

        <h3>Shop Across Categories</h3>
        <ul>
            <li>Electronics</li>
            <li>Home and Kitchen</li>
            <li>Fashion</li>
            <li>Beauty</li>
            <li>Toys & Games</li>
            <li>And more</li>
        </ul>

        <h3>Tips to Maximize Your Savings</h3>
        <ul>
            <li>Use the Amazon app for deal notifications</li>
            <li>Check the Prime Deals Day page for featured deals </li>
            <li>Look for coupons and discount codes</li>
            <li>Take advantage of Buy Now, Pay Later options</li>
        </ul>

        <h3>Don't miss out!</h3>
        <p>Visit <a className="linkText" href="https://www.amazon.com/primebigdealdays?tag=mh0b-20&hvadid=78134257739015&hvqmt=e&hvbmt=be&hvdev=c&ref=pd_sl_6sevfa875n_e&discounts-widget=%22%7B%5C%22state%5C%22%3A%7B%5C%22refinementFilters%5C%22%3A%7B%7D%7D%2C%5C%22version%5C%22%3A1%7D%22">Amazon</a> now to start shopping and make the most of this incredible 48-hour sale.</p>

        <h3>Important Dates:</h3>
        <ul>
            <li><b>Prime Deals Day: 10/8/2024
            </b></li>
            <li><b>Event Ends: 10/9/2024, 11:59 PM PT
            </b></li>
        </ul>

        <h5>Stay Connected: 
        </h5>

        <h6>
        Follow our deals page for live updates, and deal alerts</h6>
        <div style={{ width: 'min-content', marginBottom: '12px' }}>
            <Link to="/deals" className='linkText'>https://www.dcthriftshop.com/deals </Link>

        </div>
<h6 style={{ marginTop: '12px' }}>
Follow our tips page for shopping tips</h6>

        <div style={{ width: 'min-content', margin: 0 }}>
            <Link to="/tips" className='linkText'>https://www.dcthriftshop.com/tips </Link>
        </div>

        <p style={{ marginTop: '12px' }}>Happy shopping!
        </p>
</div>
        </div>
    )
}