import fresh from '../assets/amazonFresh.jpg';
import gaming from '../assets/primeGaming.jpg';
import video from '../assets/primeVideo.jpg';
import { Button } from 'react-bootstrap';

export default function Prime () {
    return (
        <div className="postContainer">
        <div className="post">
            <h1>The Top Benefits of Amazon Prime for Shoppers
            </h1>
            <span className="postPublish">Published on October 8th, 2024</span>
            <img className="postImg" src='https://hip2save.com/wp-content/uploads/2023/05/prime-gaming-screen.jpg?w=912&resize=912%2C568&strip=all' alt="Amazon prime gaming on a laptop screen"/>

            <p>Amazon Prime has grown from a convenient membership option for free shipping to an all-encompassing service that enhances the shopping experience. Whether you're a frequent shopper, movie buff, gamer, or book lover, Amazon Prime offers a wide range of benefits. In this article, we'll explore the top advantages of Amazon Prime that can make your online shopping experience even better!</p>
            

            <h3>Free and Fast Shipping</h3>
            <p>One of the most popular benefits of Amazon Prime is free two-day shipping on millions of items. For members in select areas, Amazon also offers same-day or even two-hour delivery for groceries and essentials.</p>
            <p>Imagine never having to pay for shipping again, even if you're ordering something as small as a pair of socks or as large as a TV!</p>
            <p>This fast and free shipping option makes Prime perfect for last-minute gift shopping or when you need to stock up on household essentials in a hurry.
            </p>

            <h3>Prime Video Streaming</h3>

            <p>
                With an Amazon Prime membership, you get access to Prime Video, one of the largest streaming libraries available. Prime Video features popular movies, TV series, and original Amazon content like The Boys, The Marvelous Mrs. Maisel, and The Lord of the Rings: The Rings of Power. The service is included at no extra cost with your membership.<br></br>

                Why pay for multiple streaming services when Prime offers it all in one package?<br></br>

                If you're an avid movie lover or TV binge-watcher, Prime Video will add tons of entertainment value to your membership. Plus, you can download shows and movies to watch offline when you're traveling.
            </p>
            <img className="postImg" src={ video } alt="Amazon Prime video logo"/>


            <h3>Exclusive Deals and Discounts</h3>
            <p>
                Prime members enjoy early access to Lightning Deals, exclusive discounts, and even Prime Day, an annual two-day event offering some of the biggest discounts across Amazon. If you're a bargain hunter, these exclusive offers can save you a significant amount of money throughout the year.
                From gadgets to clothing, there's always something on sale for Prime members.
                Make sure you're ready for these sales events to snag discounts on top brands or everyday items at unbeatable prices.
            </p>

            <h3>Amazon Fresh and Whole Foods Market Discounts</h3>
            <p>
                With Amazon Prime, you also gain access to Amazon Fresh grocery delivery and exclusive savings at Whole Foods Market. You can get your groceries delivered to your doorstep in just a few hours and save on top of that with special Prime member pricing.

                Shopping for groceries online has never been easier, especially if you're looking to save time and money.

                This feature is particularly useful for busy families and individuals who want to streamline their grocery shopping and save on high-quality organic products.
            </p>

            <img className="postImg" src={ fresh } alt="Amazon Fresh & whole foods logo"/>

            <h3>Prime Reading and Amazon First Reads</h3>
            <p>If you're a bookworm, Amazon Prime's Prime Reading program offers free access to over 1,000 eBooks, magazines, comics, and more. Plus, with Amazon First Reads, you can get early access to new books and even download them before their official release.

                Why limit yourself to buying books when you can have a virtual library at your fingertips?

                You can enjoy these reads on any device using the Kindle app, making it a great perk for readers who are always on the go.
            </p>

            <h3>Prime Gaming: Free Games and In-Game Content</h3>
            <p>Gamers will love Prime Gaming, which provides free monthly games and exclusive in-game content for popular titles like League of Legends, Fortnite, and Apex Legends. You also get a free subscription to a Twitch channel of your choice every month, enhancing your gaming experience.
            </p>

            <img className="postImg" src={ gaming } alt="Amazon Prime Gaming logo"/>


            <h3>Amazon Family: Discounts and Perks for Parents</h3>
            <p>Parents can benefit from Amazon Family, a Prime-exclusive feature offering up to 20% off on diaper subscriptions and exclusive deals tailored for families. Plus, Amazon provides recommendations for child-friendly books, toys, and products.

                Parenting is hard enough—let Amazon Family help save time and money on essential items for your little one.


                From baby products to children's books, Amazon Family has your back with discounted subscriptions and other perks.
            </p>

            <h4>Is Amazon Prime Worth It?</h4>
                <p>With a host of features ranging from free shipping to streaming services, exclusive deals, and gaming perks, Amazon Prime offers something for everyone. The benefits easily outweigh the membership cost, especially for frequent shoppers or those looking to consolidate services into one convenient platform.
                </p>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}>
                <Button variant="primary" style={{ width: '35%'}}>
                        <a href="https://www.amazon.com/amazonprime" style={{ textDecoration: 'none', color: 'white', lineHeight: '1' }}> Sign Up Here</a>
                    </Button>
                </div>
            </div>
        </div>
    )
}