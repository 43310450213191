import { Card, Container } from "react-bootstrap";
import NewsCarousel from "./NewsCarousel";
import TipsCarousel from "./TipsCarousel";

export default function Home () {
    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexFlow: 'column', width: '100%', }}>
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexFlow: 'column', marginBottom: '30px', paddingBottom: '20px', borderBottom: '2px solid #D3D3D3', width: '80%' }}>

                <h1 style={{ marginTop: '2rem' }}>Welcome to DC Thrift Shop</h1>
                <p style={{ marginBottom: '.5rem' }}>
                Your Ultimate Destination for Unbeatable Deals
                </p>
            </div>
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexFlow: 'column', marginBottom: '.5rem', paddingBottom: '20px', borderBottom: '2px solid #D3D3D3', width: '80%' }}>
                <h3 style={{ marginBottom: '2rem' }}>News</h3>
                <NewsCarousel />
            </div>
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexFlow: 'column', marginTop: '2rem', paddingBottom: '20px', borderBottom: '2px solid #D3D3D3', width: '80%' }}>
                <h3 style={{ marginBottom: '2rem' }}>Tips from our team</h3>
                <TipsCarousel />
            </div>
            <div style={{ width: '85%', marginTop: '2rem' }}>
                <h4 style={{ marginBottom: '1rem' }}>Empowering Thrifty Shoppers and E-commerce Businesses Alike</h4>
                <p>
                At DC Thrift Shop, we're revolutionizing the thrift shopping experience through cutting-edge technology. Our innovative platform streamlines inventory sourcing, ensuring access to the best deals on the market.
                </p>
            </div>
            <Container fluid style={{ display: 'flex', flex: 'flex-wrap', flexFlow: 'row wrap', alignContent: 'center', alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
            <Card className="homeBottomCard">
            <h4>
                        For Shoppers:
                    </h4>
                    <p>
                        Discover exceptional bargains on Amazon storefront products, carefully curated just for you.
                        Explore our constantly updated selection of hidden gems and must-haves.
                        Enjoy a seamless, user-friendly shopping experience.
                    </p>

                    </Card>

                    <Card className="homeBottomCard">
                    <h5>Support Our Mission</h5>
                    <p style={{ paddingLeft: 20, paddingRight: 20, margin: 0}}>
                    If you find a great deal through our platform, we kindly ask that you use our affiliate links to make your purchase as we receive a small commission on purchased products.</p>
                </Card>

                <Card className="homeBottomCard">
                <h4>
                    For E-commerce Businesses:
                </h4>
                <p>
                    Partner with us to optimize your product offerings and boost customer satisfaction.
                    Leverage our expertise in retailer APIs to streamline inventory sourcing.
                    Gain insights into market trends and customer preferences.
                </p>

                </Card>
            </Container>
        </div>
    );
  };