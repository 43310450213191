import TipsCarousel from "./TipsCarousel";

export default function Tips () {
    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexFlow: 'column', width: '100%', }}>

        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexFlow: 'column', marginBottom: '30px', paddingBottom: '20px', width: '80%' }}>
        <h3 style={{ marginTop: '2rem', marginBottom: '2rem' }}>Tips from our team</h3>
            <TipsCarousel />
        </div>
    </div>
);
}