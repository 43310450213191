

export default function AffiliateDisclosure() {
    return (
        <div className="postContainer">
        <div className="post">            
            <h1 style={{ margin: 20, padding: 20, paddingBottom: 20 , textAlign: 'center'}}>Amazon Affiliate Disclosure</h1>
            <p>
                DC Thrift Shop is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees.
            </p>

            <h3>Important Disclosure:</h3>
            <p>
                We may earn commissions from purchases made through our affiliate links.
                All products listed on our website are Amazon affiliate links.
                Prices and availability may vary based on your location and Amazon's pricing policies.
                We strive to provide accurate product information, but we are not responsible for pricing or availability errors.
            </p>
            <h3>Amazon Affiliate Program Participation:
            </h3>

            <p>As an Amazon Associate, we earn from qualifying purchases. This means that if you click on an affiliate link and make a purchase within 24 hours, we may receive a commission.
            </p>

            <h3>FTC Compliance:
            </h3>
            <p>In compliance with the Federal Trade Commission (FTC) guidelines, we disclose that:
Some of the links on our website are affiliate links.
We may receive compensation for purchases made through these links.
Our affiliate relationships do not influence our product selections or recommendations.
</p>

<h3>Additional Disclosures:
</h3>

<p>We do not collect or store any personal data related to your Amazon purchases.
Amazon's Privacy Policy applies to all purchases made through our affiliate links.
</p>

<h3>Thank You for Supporting Us:
</h3>
<p>By shopping through our affiliate links, you support our mission to provide exceptional deals and thrift shopping experiences.
</p>

<h3>Amazon Store Notice:
</h3>

<p>This website is not owned or operated by Amazon. Product names, logos, brands, and other trademarks featured or referred to within this website are the property of their respective trademark holders.
</p>
<h3>Disclosure Location:
</h3>
<p>To ensure compliance, we will display this disclosure in our website's footer.</p>

<h3>Updates:
</h3>
<p>We reserve the right to update this disclosure policy at any time.
By using our website, you acknowledge that you have read, understand, and agree to this Amazon Affiliate Disclosure.
</p>
</div>
        </div>
    )
}