

export default function PrivacyPolicy () {
    return (
        <div className="postContainer">
        <div className="post">            
            <h1 style={{ margin: 20, padding: 20, paddingBottom: 20 , textAlign: 'center'}}>Privacy Policy</h1>
                <span style={{ textAlign: 'center', paddingBottom: 20}}>Effective Date: 2024-10-03</span>
                <p>DC Thrift Shop ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and protect personal information obtained through our website dcthriftshop.com (the "Website").
                </p>

                <h4>Information Collection
                </h4>
                <p>Personal Information: We do not collect personal information unless you voluntarily provide it to us. We may collect:
                </p>
                <ul>
                    <li>Name
                    </li>
                    <li>Email address</li>

                    <li>Contact information (phone number, address)</li>
                </ul>
                <p>Non-Personal Information: We automatically collect:
                </p>

                <ul>
                    <li>IP address
                    </li>
                    <li>Browser type</li>

                    <li>Operating system</li>
                    <li>Pages visited</li>

                    <li>Referring URLs</li>
                    <li>Device information</li>
                </ul>

                <h4>Information Use
                </h4>
                <p>Personal Information: We use personal information to:
                </p>
                <ul>
                    <li>Respond to inquiries</li>
                    <li>Provide customer support</li>
                    <li>Send newsletters and promotional emails (if opted-in)</li>
                </ul>
                <p>Non-Personal Information: We use non-personal information to:
                </p>

                <ul>
                    <li>Analyze website usage</li>
                    <li>Improve website performance</li>
                    <li>Enhance user experience</li>
                </ul>
                <h4>Information Disclosure
                </h4>
                <p>We do not sell, trade, or rent your personal information to third parties. We may disclose information:
Amazon Affiliate Program: As an Amazon Associate, we participate in the Amazon Services LLC Associates Program. Amazon may collect information about your purchases.
</p>

            <p>Compliance with Laws: We may disclose information to comply with laws, regulations, or governmental requests.
            </p>
            <p>Third-Party Service Providers: We may share information with service providers (e.g., email marketing platforms) to perform specific functions.
            </p>

<h4>
Cookies and Tracking</h4>
<p>Cookies: We use cookies to personalize your experience and analyze website usage.<br></br>
Google Analytics: We use Google Analytics to track website traffic and behavior.</p>

<h4>Data Security</h4>
<p>We implement reasonable security measures to protect your information:
</p>
<ul>
    <li>Encryption: We use SSL encryption to protect data transmission.
    </li>
    <li>Access Control: We limit access to personal information.
    </li>
</ul>

<h4>Your Rights
</h4>
<ul>
    <li>Opt-Out: You can opt-out of promotional emails by clicking the unsubscribe link.
    </li>
    <li>Data Access: You can request access to your personal information.
    </li>
    <li>Data Deletion: You can request deletion of your personal information.
    </li>
</ul>

<h4>Children's Privacy
</h4>
<p>Our Website is not intended for children under 13. We do not knowingly collect personal information from children.
</p>

<h4>Changes to This Policy
</h4>
<p>We reserve the right to update this Privacy Policy at any time.
</p>
<h4>California Residents
</h4>
<p>Under California Civil Code Section 1798.83, California residents have the right to request certain information regarding disclosure of personal information to third parties.
</p>

<h4>EU Residents (GDPR)
</h4>
<p>If you are an EU resident, you have rights under the General Data Protection Regulation (GDPR). Please contact us for more information.
</p>
<p style={{ marginTop: '1rem'}}>By using our Website, you acknowledge that you have read, understand, and agree to this Privacy Policy.
</p>
</div>
        </div>
    )
}