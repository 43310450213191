import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import DealsInfo from './DealsInfo';

export default function Deals (props: any) {
  return (
    <div>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
            <div style={{ width: '85%'}}>
                <h6 className='align-self-center' style={{ fontSize: '.8rem', textAlign: 'center', color: '#899499', marginTop: '10px', marginBottom: '10px'}}>Note: Strikethrough prices are based on the average price of that particular product. The strikethrough and list price are subject to change. DCThriftShop.com may earn commission via any product purchased through our website.
                </h6>
            </div>
        </div>
            <Container fluid style={{ display: 'flex', flex: 'flex-wrap', flexFlow: 'row wrap', alignContent: 'center', alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
            {props?.data?.map((prop: any) => {
                const avg = Number(prop.ninetyDayAvg?.split('$')[1]);
                const cost = Number(prop.cost?.split('$')[1]);
                return (
                    <Card key={prop.asin} style={{ height: '500px', width: '350px',  margin: 10, padding: 10, alignItems: 'center', justifyContent: 'start', position: 'relative', display: 'block' }}>
                        <div className='cardSavings'>
                            <span>${Math.round(avg - cost)} off</span>
                        </div>
                    <Card.Img style={{ width: '200px', height: '250px', objectFit: 'cover' }} variant="top" src={ prop.image } />
                    <Card.Body style={{ alignContent: 'flex-start', maxWidth: '100%', height: '250px' }}>
                    <Card.Title style={{ display: 'block', fontSize: '.85rem',height: '85px',  wordBreak: 'break-all', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}><p>{ prop.title }</p></Card.Title>
                    <div style={{ flex: 'flex-row', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', height: '30px' }}>
                        <p style={{ textDecoration: 'line-through', opacity: '0.3', paddingRight: 10, marginBottom: '10px'}}>
                            $
                            <span>{ avg }</span>
                        </p>
                        <p style={{ marginBottom: '10px' }}>
                            { prop.cost }
                        </p>
                    </div>
                    <Button variant="primary">
                        <a href={`https://amazon.com/dp/${prop.asin}/ref=nosim?tag=dcthrift-20`} style={{ textDecoration: 'none', color: 'white', lineHeight: '1' }}> View on Amazon</a>
                    </Button>
                    <p className='dealDate'>
                        Deal discovered { new Date(prop.timestamp).toLocaleString().replace(',', '') } - <DealsInfo />
                    </p>
                    </Card.Body>
                </Card>
                );
            })}
        </Container>
    </div>
  );
};