export default function AboutUs () {
    return (
        <div className="postContainer">
        <div className="post">
        <h1 style={{ margin: 20, padding: 20, paddingBottom: 20 , textAlign: 'center'}}>About Us</h1>
        <p style={{ paddingLeft: 20, paddingRight: 20 }}>We're the DC Thrift Shop team: a tight-knit group of software engineers, e-commerce entrepreneurs, and deal-hunters united by a single mission. We leverage our collective expertise to build innovative solutions that connect customers with incredible bargains, making thrift shopping easier, more enjoyable, and remarkably rewarding.</p>
        <p style={{ paddingLeft: 20, paddingRight: 20 }}>
            Currently, our focus is on curating exceptional deals from Amazon storefront products. However, our vision extends far beyond. We're committed to expanding our reach, integrating with additional e-commerce platforms, and exploring new marketplaces to bring our customers an unparalleled thrift shopping experience.
        </p>
        </div>
    </div>
    );
  };