import { Card, CardTitle, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsiveCarousel } from "../responsive";
import BuyBox from '../assets/Amazon-Buy-Box.png';
import aiCover from '../assets/aiCover.jpeg';

export default function TipsCarousel () {
    return (
        <Container>
        <Carousel 
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            autoPlay={false}
            responsive={responsiveCarousel}
            rewind={true}
            rewindWithAnimation={false}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            > 
                <Card className="homeCard">
                        <Link to="/smartShoppingAi" style={{ textDecoration: 'none', color: 'black'  }}>
                            <div>
                                <img className="homeCardImage" src={ aiCover } alt="Cartoon shopping cart with AI"/>
                                <Container className="homeCardBody">
                                    <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>Smart Shopping: Using AI to Stay Ahead of Trendy Products</CardTitle>
                                    <span className="homePostPublish">Published on October 13th, 2024</span>
                                </Container>
                            </div>
                        </Link>
                </Card>

                <Card className="homeCard">
                        <Link to="/tradeIn" style={{ textDecoration: 'none', color: 'black'  }}>
                            <div>
                                <img className="homeCardImage" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyE8fN3aY4fqr9ttHPaJXomt1nvUQskEECHw&s' alt="Cartoon trade in swap"/>
                                <Container className="homeCardBody">
                                    <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>Saving with Amazon's Trade-In Program</CardTitle>
                                    <span className="homePostPublish">Published on October 10th, 2024</span>
                                </Container>
                            </div>
                        </Link>
                </Card>

                <Card className="homeCard">
                        <Link to="/amazonWarehouse" style={{ textDecoration: 'none', color: 'black'  }}>
                            <div>
                                <img className="homeCardImage" src='https://www.sellersnap.io/wp-content/uploads/2022/12/Cover_Blog-Post-Images-3.jpg' alt="Understanding Warehouse deals"/>
                                <Container className="homeCardBody">
                                    <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>How to Use Amazon Warehouse to Find Better Deals</CardTitle>
                                    <span className="homePostPublish">Published on October 9th, 2024</span>
                                </Container>
                            </div>
                        </Link>
                </Card>

                <Card className="homeCard">
                        <Link to="/primeBenefits" style={{ textDecoration: 'none', color: 'black'  }}>
                            <div>
                                <img className="homeCardImage" src='https://hip2save.com/wp-content/uploads/2023/05/prime-gaming-screen.jpg?w=912&resize=912%2C568&strip=all' alt="Amazon prime gaming on a laptop screen"/>
                                <Container className="homeCardBody">
                                    <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>The Top Benefits of Amazon Prime for Shoppers</CardTitle>
                                    <span className="homePostPublish">Published on October 8th, 2024</span>
                                </Container>
                            </div>
                        </Link>
                </Card>
                <Card className="homeCard">
                        <Link to="/buyBox" style={{ textDecoration: 'none', color: 'black'  }}>
                            <div>
                                <img className="homeCardImage" src={ BuyBox } alt="Amazon buy box"/>
                                <Container className="homeCardBody">
                                    <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>Unlocking the Power of Amazon Listings: Understanding the Buy Box and Finding Better Deals</CardTitle>
                                    <span className="homePostPublish">Published on October 7th, 2024</span>
                                </Container>
                            </div>
                        </Link>
                </Card>

                <Card className="homeCard">
                    <Link to="/tips" style={{ textDecoration: 'none', color: 'black'}}>
                        <div>
                            <img className="homeCardImage" src="https://elitedigitalagency.com/blog/wp-content/uploads/5-digital-marketing-strategies-to-improve-conversions.jpg" alt="5 digital marketing strategies"/>
                            <Container className="homeCardBody">
                                <CardTitle style={{ marginBottom: '50px'}}>Boost Conversions with Product Recommendation Strategies</CardTitle>
                                <span className="homePostPublish">Published on October 5th, 2024</span>
                            </Container>
                        </div>
                    </Link>
                </Card>
        </Carousel>
    </Container>
    )
}