import NewsCarousel from "./NewsCarousel";

export default function News () {
    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexFlow: 'column', width: '100%', }}>

        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexFlow: 'column', marginBottom: '30px', paddingBottom: '20px', width: '80%' }}>
            <h3 style={{ marginTop: '2rem', marginBottom: '2rem' }}>News</h3>
            <NewsCarousel />
        </div>
    </div>
);
}