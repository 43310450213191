export const responsiveCarousel = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1150 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1150, min: 830 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 830, min: 0 },
      items: 1,
    },
  };
