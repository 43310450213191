import { Card, CardTitle, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import primeDeals from '../assets/primeDeals.jpg';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsiveCarousel } from "../responsive";


export default function NewsCarousel() {
return (
    <Container>
    <Carousel 
additionalTransfrom={0}
arrows
centerMode={false}
className=""
containerClass="container-with-dots"
dotListClass=""
draggable
focusOnSelect={false}
itemClass=""
keyBoardControl
minimumTouchDrag={80}
pauseOnHover
renderArrowsWhenDisabled={false}
renderButtonGroupOutside={false}
autoPlay={false}
responsive={responsiveCarousel}
rewind={true}
rewindWithAnimation={false}
showDots={false}
sliderClass=""
slidesToSlide={1}
swipeable
>
<Card className="homeCard">
                    <Link to="/primeDayOver" style={{ textDecoration: 'none', color: 'black'  }}>
                        <div>
                            <img className="homeCardImage" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8HT3w9W75tU7HTVk5xTeW3eBjv71mDinjCw&s' alt="This Sale has Ended text with shopping cart cartoon"/>
                            <Container className="homeCardBody">
                                <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>Amazon Prime Deals Day is Over...</CardTitle>
                                <span className="homePostPublish">Published on October 11th, 2024</span>
                            </Container>
                        </div>
                    </Link>
            </Card>

            <Card className="homeCard">
                    <Link to="/primeStartsNow" style={{ textDecoration: 'none', color: 'black'  }}>
                        <div>
                            <img className="homeCardImage" src='https://www.tennessean.com/gcdn/authoring/authoring-images/2024/06/24/USAT/74193726007-prime-day-faq-62524.png?crop=2997,1685,x0,y0&width=2997&height=1685&format=pjpg&auto=webp' alt="Prime Day Deals with pink box"/>
                            <Container className="homeCardBody">
                                <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>BREAKING: Amazon Prime Deals Day is Here!</CardTitle>
                                <span className="homePostPublish">Published on October 8th, 2024</span>
                            </Container>
                        </div>
                    </Link>
            </Card>

        <Card className="homeCard">
                    <Link to="/primeDealDays" style={{ textDecoration: 'none', color: 'black'  }}>
                        <div>
                            <img className="homeCardImage" src={ primeDeals } alt="Prime Big Day Deals logo"/>
                            <Container className="homeCardBody">
                                <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>Amazon Prime Big Deals Day: Get Ready for 48 Hours of Epic Saving</CardTitle>
                                <span className="homePostPublish">Published on October 6th, 2024</span>
                            </Container>
                        </div>
                    </Link>
            </Card>

<Card className="homeCard">
                <Link to="/blog" style={{ textDecoration: 'none', color: 'black'  }}>
                    <div>
                        <img className="homeCardImage" src="https://news.virginia.edu/sites/default/files/Header-SF-DockWorkerStrike-JD.jpg" alt="Dock worker strike"/>
                        <Container className="homeCardBody">
                            <CardTitle style={{ alignItems: 'bottom', justifyContent: 'botom' }}>Port Strikes: How Labor Disputes Could Disrupt Your Amazon Deliveries</CardTitle>
                            <span className="homePostPublish">Published on October 3rd, 2024</span>
                        </Container>
                    </div>
                </Link>
        </Card>
</Carousel>
    </Container>

)
}