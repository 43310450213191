import primeBigDealDays from '../assets/primeBigDealDays.jpeg';
import primeDeals from '../assets/primeDeals.jpg';
import primeDealsApp from '../assets/primeDealsApp.jpg';

export default function PrimeDay() {
    return (
        <div className="postContainer">
        <section className="post">
            <h1> Amazon Prime Big Deals Day: Get Ready for 48 Hours of Epic Savings</h1>
            <span className="postPublish">Published on October 6th, 2024</span>

            <img className="postImg"src={ primeDeals} alt="Amazom prime deals"/>

            <p>Mark your calendars! Amazon's Prime Big Deals Day is just around the corner, taking place on October 8-9. This highly anticipated shopping event promises to deliver unbeatable deals, discounts, and savings on a vast range of products. In this article, we'll guide you through what to expect, how to prepare, and tips to make the most of this exciting 48-hour shopping extravaganza.</p>

            <h3>What is Prime Big Deals Day?</h3>
            <p>
            Prime Big Deals Day is a special shopping event exclusively for Amazon Prime members. This event offers:
            </p>
            <ul>
                <li>Deep discounts on top brands and best-selling items
                </li>
                <li>Exclusive deals on new releases and popular products
                </li>
                <li>Free shipping and fast delivery options
                </li>
            </ul>

            <img className="postImg"src={ primeBigDealDays} alt="Amazom prime big deal days"/>

            <h3>Benefits of Prime Big Deals Day</h3>
            <p>
                Unbeatable Prices: Enjoy significant savings on millions of products.
                Early Access: Prime members get exclusive access to deals 30 minutes before non-Prime members.
                Free Shipping: Get fast and free shipping on eligible items.
            </p>
            <h3>How to Prepare for Prime Big Deals Day
            </h3>
            <ul>
                <li>
                Sign up for Amazon Prime: Ensure you're a Prime member to access exclusive deals.
                </li>
                <li>
                Create a Wish List: Add items you want to purchase and track price drops.
                </li>
                <li>
                Use the Amazon App: Get notified about upcoming deals and receive personalized recommendations.
                </li>
            </ul>

            <img className="postImg"src={ primeDealsApp} alt="Amazom prime deals app on phone"/>

            <h3>Tips and Tricks
            </h3>
            <ul>
                <li>Start Early: Deals go live at midnight on October 8, so plan ahead.
                </li>
                <li>Use Price Comparison Tools: Check prices against other retailers.
                </li>
                <li>Look for Bundle Deals: Save even more by purchasing related items together.
                </li>
                <li>Check Deal Pages: Visit Amazon's dedicated Prime Big Deals Day page for the latest deals.
                </li>

            </ul>

            <h3>Popular Categories to Watch
            </h3>
            <ul>
                <li>Electronics
                </li>
                <li>Home and Kitchen
                </li>

                <li>Fashion
                </li>

                <li>Beauty and Personal Care
                </li>

                <li>Toys and Games
                </li>

            </ul>

            <p>
            Amazon Prime Big Deals Day is a shopper's dream come true. With unbeatable deals, exclusive access, and free shipping, this 48-hour event is not to be missed.
            </p>
            <span>
                Get Ready to Shop!<br></br>
                Stay tuned for the latest updates, and get ready to save big on October 8-9.
            </span>
        </section>
        </div>
    )
}