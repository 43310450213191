import november from '../assets/november24.jpg';
import { Link } from 'react-router-dom';

export default function PrimeDayOver() {
    return (
        <div className="postContainer">
        <div className="post">            
            <h1>Amazon Prime Deals Day is Over... But Don't Worry, More Savings are Coming!</h1>
            <img className="postImg" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8HT3w9W75tU7HTVk5xTeW3eBjv71mDinjCw&s' alt="This Sale has Ended text with shopping cart cartoon" />

            <p>After a thrilling 48-hour shopping spree, Amazon Prime Deals Day has officially come to a close. But fear not, savvy shoppers! There are plenty of upcoming deals and sales to look forward to.
            </p>

            <h4>Mark Your Calendars: Upcoming Sales Events
            </h4>

            <img className="postImg" src={ november } alt="Calendar with November 24th circled in red" />

            <ul>
                <li><b>Black Friday and Cyber Monday (November 24-27):</b> Get ready for the biggest shopping weekend of the year, with door-busting deals on electronics, toys, home goods, and more.</li>
                <li><b>Green Monday (December 11):</b> Considered the best sales day in December, Green Monday offers significant discounts on popular items.</li>
                <li><b>Free Shipping Day (December 14):</b> Enjoy free shipping with no minimum purchase requirement from participating retailers.
                </li>
                <li><b>After-Christmas Sales (December 26-31):</b> Snag post-holiday deals on winter clothing, home decor, and holiday items.
                </li>
            </ul>

            <h4>Additional Savings Opportunities
            </h4>

            <ul>
                <li><b><a className="linkText" href="https://www.woot.com/alldeals">Woot! Deals:</a></b> Our partners at Woot! offer daily deals on unique products, often at unbeatable prices.</li>
                <li><b><a href='https://www.bestbuy.com/site/misc/deal-of-the-day/pcmcat248000050016.c' className='linkText'>Best Buy's Deal of the Day:</a></b> Check Best Buy's website daily for exclusive discounts on electronics and more.</li>
                <li><b><Link className='linkText' to="/deals">Our Exclusive Deals Page:</Link></b> Visit our website regularly for handpicked deals, discounts, and promotions from top brands.</li>
            </ul>

            <h4>Stay Ahead of the Game
            </h4>
            <p>We're constantly posting updates, new, tips, and deals to our website. Check in often to stay up to date. We don't want you to miss out.<br></br>
            Happy shopping!

            </p>
</div>
        </div>
    )
}