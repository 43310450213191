import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import logo from '../assets/bg_traced.png';

function NavBar() {
  return (
    <Navbar expand="lg" style={{ background: '#F0F0F0'}}>
      <Container>
        <Navbar.Brand style={{ alignSelf: 'left', width: '125px'}}>
        <Link to="/" style={{ textDecoration: "none", color: "black",}}>
            <div>
                <img src={logo} alt='DcThriftShop logo'style={{ width: '100%' }}/>
            </div>
        </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav className="justify-content-end">
                <Link role="button" className="nav-link" style={{ textDecoration: "none", color: "black" }} to="/">Home</Link>
                <Link role="button" className="nav-link" style={{ textDecoration: "none", color: "black" }} to="/news">News</Link>
                <Link role="button" className="nav-link" style={{ textDecoration: "none", color: "black" }} to="/tips">Tips</Link>
                <Link role="button" className="nav-link" style={{ textDecoration: "none", color: "black" }} to="/deals">Deals</Link>
                <Link role="button" className="nav-link" style={{ textDecoration: "none", color: "black" }} to="/aboutUs">About Us</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;