import tradeInEx from '../assets/amazonTradeInEx.png';
import { Link } from 'react-router-dom';

export default function TradeIn() {
    return (
        <div className="postContainer">
        <div className="post">
            <h1>Saving with Amazon's Trade-In Program</h1>

            <img className="postImg" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyE8fN3aY4fqr9ttHPaJXomt1nvUQskEECHw&s' alt="Cartoon trade in swap" />


            <p>Are you looking for ways to declutter your home and earn some extra cash? Amazon's Trade-In Program is a great solution! 
            This program allows you to trade in your used items for Amazon gift cards, which can be used to purchase new products on the platform.</p>

            <h3>How it Works</h3>
            <ul>
                <li>
                Find eligible items: Check if your used items are eligible for trade-in on Amazon.
                </li>
                <li>
                Get an estimate: Enter the item's condition and receive an instant trade-in value estimate.
                </li>
                <li>
                Ship for free: Print a pre-paid shipping label and send the item to Amazon.
                </li>
                <li>
                Receive gift card: Get an Amazon gift card equal to the trade-in value.
                </li>
            </ul>

            <h3>Eligible Items
            </h3>

            <h5>Amazon accepts a wide range of used items, including:
            </h5>

            <ul>
                <li>Books</li>
                <li>CDs</li>
                <li>DVDs</li>
                <li>Video games</li>
                <li>Electronics</li>
                <li>Cell phones</li>
            </ul>

            <img className="postImg" src={ tradeInEx } alt="Examples of Amazon Trade In Eligibe items" />


            <h3>Benefits</h3>

            <ul>
                <li><b>Declutter your home:</b> Get rid of unwanted items and free up space.</li>
                <li><b>Earn gift cards:</b> Receive Amazon gift cards to use on future purchases.</li>
                <li><b>Convenience:</b> Easy trade-in process with free shipping.</li>
                <li><b>Environmentally friendly:</b> Reduce electronic waste by recycling your used items.</li>
            </ul>

            <h3>Tips and Tricks</h3>
            <ul>
                <li><b>Accurately describe condition:</b> Ensure you receive the correct trade-in value.</li>
                <li><b>Check prices:</b> Compare trade-in values with selling prices on Amazon.</li>
                <li><b>Combine trade-ins:</b> Trade in multiple items at once for a larger gift card.</li>
            </ul>

            <p>
            Amazon's Trade-In Program is a convenient and rewarding way to declutter your home and earn gift cards. Start trading in your used items today!
            </p>

            <h5>Additional Resources
            </h5>

            <p><b>Amazon Trade-In Program:</b> <a className="linkText" href="https://www.amazon.com/Amazon-Trade-In/b?node=9187220011">www.amazon.com/Amazon-Trade-In</a></p>
            <p><b>Eligible items:</b> <a className="linkText" href="https://www.aboutamazon.com/news/devices/amazon-trade-in-program">www.aboutamazon.com/news/devices/amazon-trade-in-program</a></p>
            <p>Check out our tips page for more tips like this one: <Link className='linkText' to="/tips">Here</Link></p>
        </div>
        </div>
    )
}