export default function TermsOfUse () {
    return (
        <div className="postContainer">
        <div className="post">
        <h1 style={{ margin: 20, padding: 20, paddingBottom: 20 , textAlign: 'center'}}>Terms of Use</h1>
            <h2 style={{ textAlign: 'center' }}>DC Thrift Shop Terms of Use</h2>
<span style={{ textAlign: 'center', paddingBottom: 20}}>Effective Date: 2024-10-03</span>
    <p>
Welcome to DC Thrift Shop ("we," "us," or "our")! These Terms of Use ("Terms") govern your access to and use of our website [(DCThriftShop.com)] (the "Website").

        <h3>Acceptance</h3>
        <p>
        By using the Website, you agree to these Terms. If you do not agree, please do not use the Website.   
        </p>

        <h3>Website Purpose
        </h3>

        <p>
        The Website provides:
        </p>
        <ul>
            <li>
            Information about products available on Amazon.
            </li>
            <li>
            Affiliate links to Amazon products.
            </li>
            <li>
            Thrift shopping resources.
            </li>
        </ul>

        <h3>User Responsibilities
        </h3>
        <p>You agree:</p>
        <ul>
            <li>
            To use the Website for lawful purposes only. 
            </li>
            <li>Not to infringe on our or others' intellectual property rights.
            </li>
            <li>Not to harass, abuse, or harm others.
            </li>
            <li>
            To provide accurate information.
            </li>
        </ul>
        <h3>Prohibited Activities
        </h3>
        <p>You may not:</p>

        <ul>
            <li>
            Use automated scripts or bots.
            </li>
            <li>
            Interfere with Website operations.
            </li>
            <li>
            Upload malicious content.
            </li>
            <li>
            Violate Amazon's policies.
            </li>
        </ul>

        <h3>Intellectual Property
        </h3>
        <p>Our Website content is proprietary.<br></br>
            Amazon's trademarks and copyrights apply.
        </p>

        <h3>Disclaimers</h3>
        <p>We disclaim warranties of any kind. We are not liable for damages or losses.
        </p>

        <h3>Limitation of Liability
        </h3>
        <p>Our liability is limited to the fullest extent permitted by law.
        </p>
        

        <h3>Indemnification</h3>
        <p>You agree to indemnify and hold us harmless.
        </p>
        
        <h3>Termination</h3>
        <p>We may terminate or suspend your access.
        </p>
<h4 style={{ paddingBottom: 10, paddingTop: 10 }}>Governing Law</h4>
These Terms are governed by Virginia law.
Changes
We reserve the right to update these Terms.
<h4 style={{ paddingBottom: 20, paddingTop: 20 }}>Contact</h4>
For questions or concerns, contact us at:<br></br>
<a className="linkText" href="mailto:support@dcthriftshop.com">support@dcthriftshop.com</a>
<br></br>
Additional Terms
Amazon Affiliate Disclosure: Our Amazon Affiliate Disclosure applies.
Privacy Policy: Our Privacy Policy applies.
By using the Website, you acknowledge that you have read, understand, and agree to these Terms</p>
</div>
</div>
    );
};